@import "../colors.scss";

.container-login {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $azul-3a;
}

.card-login {
  max-width: 500px;
  min-width: 500px;
  width: 500px;
  padding: 10px;
  text-align: center;
  background: $gray-5;
  border-radius: 10px;
  overflow-x: auto;
}

.card-login img {
  width: 250px;
  margin: 15px 5px;
}

.form-login {
  width: 90%;
  margin: 10px auto;
}

@media only screen and (max-width: 1000px) {
  .container-login {
    display: unset;
    align-items: unset;
    justify-content: unset;
    background: $azul-3a;
  }

  .card-login {
    max-width: unset;
    min-width: unset;
    width: unset;
    margin: 10px 30px;
    padding: 10px;
    text-align: center;
    background: $gray-1;
  }
}

@media only screen and (max-width: 1000px) and (orientation: landscape) {
  .container-login {
    display: unset;
    align-items: unset;
    justify-content: unset;
    background: $azul-3a;
  }

  .card-login {
    max-width: 450px;
    min-width: 450px;
    width: 450px;
    margin: auto;
    padding: 10px;
    text-align: center;
    background: $gray-1;
  }
}
