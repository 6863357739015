@import "../colors.scss";

/* Dashboard Page */
#layout-main {
  min-height: 100vh !important;
}

#img-logo {
  margin: 10px 0px 0px 0px !important;
}

#header {
  text-align: end !important;
  padding-right: 10px !important;
}

.button-logout {
  background: #ffffff !important;
  border-radius: 10px !important;
  border: unset !important;
}

.button-logout button {
  border: unset !important;
}

#content {
  padding: 20px !important;
}

#footer {
  text-align: center !important;
}

@media only screen and (max-width: 1000px) {
}

@media only screen and (max-width: 1000px) and (orientation: landscape) {
}
